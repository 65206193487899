<template>
  <div v-loading="loading" class="reward-activity-product-block">
    <p class="card-title">適用活動商品設定</p>
    <BaseElForm ref="formRef" label-position="top" :model="productIds" :rules="formRules">
      <BaseElSelect v-model="selectData.type" @change="onSelectChange">
        <BaseElSelectOption
          v-for="(item, index) in selectProductConfig"
          :key="index"
          :value="item.value"
          :label="item.label"
        />
      </BaseElSelect>
      <div v-if="selectData.type === 'category'" class="pt-3 el-form-item__content">
        <p>請選擇指定類別</p>
        <SalesCategorySelect :model.sync="selectData.category" :showAll="true" multiple />
        <p>所選類別是否有要排除或是加上特定商品</p>
        <BaseElRadioGroup v-model="selectData.sourceItemsOperator" style="margin-bottom: 10px" @change="onSelectChange">
          <div class="radio-group">
            <BaseElRadio v-for="(item, index) in productCategoryConfig" :key="index" :label="item.value">
              {{ item.label }}
            </BaseElRadio>
          </div>
        </BaseElRadioGroup>
      </div>
      <div v-if="selectData.type !== 'category' || (selectData.type === 'category' && selectData.sourceItemsOperator)">
        <div class="flex justify-between items-center" style="max-width: 560px">
          <BaseElButton class="underline" type="text" @click="showProductSelectModal = true">
            {{ chooseLabel }}
          </BaseElButton>
          <BaseElButton class="underline" style="color: var(--danger)" type="text" @click="removeAll">移除全部</BaseElButton>
        </div>
        <BaseElFormItem
          v-for="(value, key) in productIds"
          :key="key"
          :prop="key"
        >
          <BaseElSelect
            v-model="productIds[key]"
            popper-class="select-popper"
            placeholder="請選擇商品"
            @visible-change="advanceSelectClick(key)"
          >
            <BaseElSelectOption
              :label="findProductName(productIds[key])"
              :value="productIds[key]"
            />
          </BaseElSelect>
          <BaseElButton class="underline" style="color: var(--danger); margin-left: 16px" type="text" @click="onProductRemove(key)">移除</BaseElButton>
        </BaseElFormItem>
      </div>
    </BaseElForm>

    <SalesProductAdvanceSelect
      v-if="showProductSelectModal"
      multiple
      :data="selectableProducts()"
      :typeOptions="productTypeConfig"
      @confirm="onAddProduct"
      @close="showProductSelectModal = false"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, ref, set, del, onMounted, nextTick, computed, watch } from 'vue'
import { nanoid } from 'nanoid'
import { noEmptyRules } from '@/validation'
import { find, get, isEmpty, map } from 'lodash'
import SalesProductAdvanceSelect from '@/components/Select/SalesProductAdvanceSelect.vue'
import SalesCategorySelect from '@/components/Select/SalesCategorySelect.vue'
import { productTypeConfig } from '@/config/memberShop'
import { GetSalesCategory } from '@/api/sales'
import store from '@/store'

export default defineComponent({
  name: 'RewardActivityProductBlock',
  components: { SalesProductAdvanceSelect, SalesCategorySelect },
  props: ['storeProducts', 'FormsContext', 'selectProductConfig', 'activityData', 'productCategoryConfig'],
  setup (props) {
    const shopId = computed(() => store.getters.shop)
    const activityCategoryData = computed(() => {
      if (props.activityData) {
        const { sourceItemsSettings } = props.activityData
        const category = sourceItemsSettings.find(({ sourceItemType }) => {
          return sourceItemType === 'salesCategory'
        })
        return category
      }
      return null
    })
    const formRef = ref(null)
    const productIds = reactive({})
    const selectData = reactive({
      type: get(activityCategoryData.value, 'operator') || 'EXCLUDE',
      category: [],
      sourceItemsOperator: null,
    })
    const sourceItemIds = ref([])
    const formRules = {}
    const showProductSelectModal = ref(false)
    const selectProduct = ref()
    const loading = ref(false)
    const categoryData = ref(null)

    const chooseLabel = computed(() => {
      if (selectData.type === 'category') {
        const selectedItem = props.productCategoryConfig.find((item) => {
          return item.value === selectData.sourceItemsOperator
        })
        return selectedItem.chooseLabel
      }
      const selectedItem = props.selectProductConfig.find((item) => {
        return item.value === selectData.type
      })
      return selectedItem.chooseLabel
    })

    const selected = () => {
      const values = []
      for (const key in productIds) {
        values.push(productIds[key])
      }
      return values
    }

    const selectableProducts = () => {
      const products = selected()
      return props.storeProducts.filter(p => !products.includes(p.id))
    }

    const setProductIds = (products) => {
      for (const product of products) {
        const id = nanoid(10)
        set(productIds, `product_${id}`, product)
        set(formRules, `product_${id}`, [noEmptyRules()])
      }
    }

    const onAddProduct = (products) => {
      if (selectProduct.value) {
        set(productIds, selectProduct.value, products[0])
        selectProduct.value = null
        return
      }
      setProductIds(products)
    }

    const findProductName = (id) => {
      const product = find(props.storeProducts, { id })
      if (product) return product.name
      return ''
    }
    const advanceSelectClick = (key) => {
      showProductSelectModal.value = true
      selectProduct.value = key
    }

    const removeAll = () => {
      for (const key in productIds) {
        del(productIds, key)
        del(formRules, key)
      }
    }

    const onProductRemove = (key) => {
      del(productIds, key)
      del(formRules, key)
    }

    const getSalesCategory = async () => {
      loading.value = true
      const ids = get(activityCategoryData.value, sourceItemIds)
      const res = await GetSalesCategory({
        shopId: shopId.value,
        id: ids,
      })
      loading.value = false
      categoryData.value = res
    }

    const compactData = computed(() => {
      const data = {
        sourceItemsSettings: [],
        sourceItemsOperator: selectData.sourceItemsOperator === 'EXCLUDE' ? 'AND' : 'OR',
      }
      if (selectData.type === 'category') {
        data.sourceItemsSettings.push(
          {
            sourceItemType: 'salesCategory',
            operator: 'INCLUDE',
            sourceItemIds: get(selectData, 'category').map(({ id }) => id),
          },
        )
        //  類別(不排除or指定)商品，則不需要帶商品id
        if (selectData.sourceItemsOperator) {
          data.sourceItemsSettings.push({
            sourceItemType: 'salesProduct',
            operator: selectData.sourceItemsOperator,
            sourceItemIds: sourceItemIds.value,
          })
        }
      } else {
        data.sourceItemsSettings.push({
          sourceItemType: 'salesProduct',
          operator: get(selectData, 'type'),
          sourceItemIds: sourceItemIds.value,
        })
      }
      return { ...data }
    })

    const syncData = () => {
      const data = props.activityData
      const { sourceItemsOperator, sourceItemsSettings } = data
      console.log('sourceItemsOperator', sourceItemsOperator, data)
      //  先將 salesCategory、salesProduct取出
      const categorySetting = sourceItemsSettings.find(({ sourceItemType }) => {
        return sourceItemType === 'salesCategory'
      })
      const productSetting = sourceItemsSettings.find(({ sourceItemType }) => {
        return sourceItemType === 'salesProduct'
      })

      if (categorySetting) {
        set(selectData, 'type', 'category')
        set(selectData, 'category', map([...categorySetting.sourceItemIds], (i) => ({ id: i })))
        set(selectData, 'sourceItemsOperator', sourceItemsOperator === 'AND' ? 'EXCLUDE' : 'INCLUDE')

        if (isEmpty(productSetting)) {
          set(selectData, 'sourceItemsOperator', null)
        }
      } else {
        set(selectData, 'type', productSetting.operator)
      }

      //  指定類別or非指定類別類別都需將商品id帶回
      setProductIds(productSetting.sourceItemIds)
    }

    onMounted(async () => {
      await nextTick()
      if (get(props.activityData, 'id')) {
        if (activityCategoryData.value) await getSalesCategory()
        syncData()
      }
      props.FormsContext.setFormData('product', { ...compactData.value })
    })

    const onSelectChange = () => {
      removeAll()
      props.FormsContext.setFormData('product', { ...compactData.value })
    }

    watch(productIds, () => {
      sourceItemIds.value = Object.values(productIds)
      props.FormsContext.setFormData('product', { ...compactData.value })
    })

    watch(selectData, () => {
      props.FormsContext.setFormData('product', { ...compactData.value })
    })

    return {
      loading,
      formRef,
      productIds,
      formRules,
      showProductSelectModal,
      selectableProducts,
      productTypeConfig,
      onAddProduct,
      findProductName,
      advanceSelectClick,
      onProductRemove,
      selectData,
      chooseLabel,
      removeAll,
      onSelectChange,
    }
  },
})
</script>

<style lang="postcss" scoped>
.el-form-item__content {
  @apply text-gray-100 text-sub;
}
</style>
